import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import AntennaTable from 'components/Products/SMA_RP-SMA_Antenna_Connector/AntennaTable';
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Antenna Connector",
  "path": "/Products/SMA_RP-SMA_Antenna_Connector/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "All INSTAR WLAN IP cameras use either SMA or RP-SMA default antenna connectors with a screw type coupling mechanism.",
  "image": "./P_SearchThumb_SMA-RPSMA.png",
  "social": "/images/Search/P_SearchThumb_SMA-RPSMA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Antenna Connector' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='All INSTAR WLAN IP cameras use either SMA or RP-SMA default antenna connectors with a screw type coupling mechanism.' image='/images/Search/P_SearchThumb_SMA-RPSMA.png' twitter='/images/Search/P_SearchThumb_SMA-RPSMA.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/SMA_RP-SMA_Antennenanschluss/' locationFR='/fr/Products/SMA_RP-SMA_Antenna_Connector/' crumbLabel="Antenna Connector" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "antenna-connector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#antenna-connector",
        "aria-label": "antenna connector permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Antenna Connector`}</h1>
    <p>{`All INSTAR WLAN IP cameras use either SMA or RP-SMA default antenna connectors with a screw type coupling mechanism. SMA (SubMiniature version A) And RP-SMA (Reversed Polarity SubMiniature version A) have a 50 Ω impedance and are designed for use with frequencies up to 18 GHz. The SMA connector employs a 1/4"-36 thread and the male is equipped with a 5/16" hex nut and are one of the most commonly used RF/microwave connectors.`}</p>
    <h3 {...{
      "id": "sma-or-rp-sma",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sma-or-rp-sma",
        "aria-label": "sma or rp sma permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SMA or RP-SMA`}</h3>
    <p>{`RP-SMA antenna (IN-3010) on the left and a SMA antenna (IN-2904) on the right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/226d861f07c554eae1770073d52066fa/f93b5/SMA_Connector.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAEDBAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFmhKWRB//EABoQAAIDAQEAAAAAAAAAAAAAAAIDAAEEEBL/2gAIAQEAAQUCP1mPK+6KNSLYChDn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhABAAIDAQAAAAAAAAAAAAAAAQIiABARMf/aAAgBAQAGPwIssXCPourdysQ1/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMUEQIVH/2gAIAQEAAT8hqBjhcduGOGjR4xYaDddy5//aAAwDAQACAAMAAAAQkA//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qp//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExYbHx/9oACAEBAAE/ECFVcso7AmepC6TkQWavg4o38j6YVT0jRn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/226d861f07c554eae1770073d52066fa/e4706/SMA_Connector.avif 230w", "/en/static/226d861f07c554eae1770073d52066fa/e47c0/SMA_Connector.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/226d861f07c554eae1770073d52066fa/a0b58/SMA_Connector.webp 230w", "/en/static/226d861f07c554eae1770073d52066fa/c85cb/SMA_Connector.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/226d861f07c554eae1770073d52066fa/e83b4/SMA_Connector.jpg 230w", "/en/static/226d861f07c554eae1770073d52066fa/f93b5/SMA_Connector.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/226d861f07c554eae1770073d52066fa/f93b5/SMA_Connector.jpg",
              "alt": "SMA oder RP-SMA",
              "title": "SMA oder RP-SMA",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In SMA connectors, the terms male and female refer to the male center pin and its female sleeve counterpart. An example of a SMA Antenna is our camera model IN-2904, that has the center pin is on the antenna side - the camera has a "male antenna".`}</p>
    <p>{`Reverse polarity SMA (RP-SMA) is a variation of the SMA connector specification which reverses the gender of the interface. The female RP-SMA connector has the same external housing as a standard or conventional female SMA connector, which consists of an outer shell with the threads on the outside BUT the center receptacle is replaced by a male pin. An example for a RP-SMA connector is the Antenna of the camera model IN-3010 - the camera has a "female antenna". The image below shows the (female) RP-SMA antenna of a IN-3010 camera on the left and a (male) SMA antenna of a IN-2904 camera on the right.`}</p>
    <p>{`It is advisable to inspect and clean out loose debris from the internal surfaces with compressed air or a gas duster can before mating. A few hundred interconnect cycles are possible if performed carefully. Care should be taken to join connectors straight-on. Prior to making a connection it is wise to inspect the female end to assure that the center socket is in good condition.`}</p>
    <AntennaTable mdxType="AntennaTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      